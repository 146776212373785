

@mixin info {
  margin: 5px;
  font-size: 16px;
  padding: 5px;
  width: 180px;
  &:hover {
    cursor: pointer;
  }
}

body {
  background-color: #242424;
  width: 1040px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

header {
  h1 {
    margin: 5px 0;
    color: white;
  }
}

h2 {
  margin: 5px 0;
  color: white;
}

// .add {
//   position: fixed;
// }

div {
  background-color: dimgrey;
  padding: 5px 0;
  margin-top: 5px;
  form {
    .label {
      margin: 0 auto;
      padding: 0 5px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    label {
      select {
        @include info;
        width: 200px;
        background-color: rgb(55, 59, 61);
        color: white;
        &:hover {
          opacity: 0.8;
        }
      }
      input {
        @include info;
      }
    }
  }
}
button {
  background-color: cornflowerblue;
  border: none;
  color: white;
  margin: 1px 5px;
  padding: 10px;
  font-size: 14px;
  &:hover {
    background-color: rgb(17, 74, 110);
    transform: scale(1.2);
  }
}
div {
  span {
    display: inline-block;
    text-align: left;
    width: 120px;
    font-size: 16px;
    color: white;
  }
}

hr {
  margin: 3px;
}
@media (max-width: 900px){
  body{
    width: 100%;
  }
}